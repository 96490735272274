<template>
  <div>
    <standard-page title="Currencies" :definition="$DEFINITIONS.admin.currencies">
      <template v-slot:breadcrumb>
        <span>
          <router-link :to="{ name: 'admin-home'}">Admin</router-link>
        </span>
        <span>Currencies</span>
      </template>

      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Currency">
          <i class="fas fa-plus-square mg-r-10" />Add Currency
        </button>
      </template>

      <template v-slot:content>
        <data-table table-id="currencies_table" :server-side="false" :api="api" :columns="columns" list-key="currencies" @ready="onReady">
        </data-table>
      </template>
    </standard-page>

    <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Currency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('name1')}" class="form-control" id="name1" name="name1" data-vv-as="Name" v-validate="'required'" v-model="currency.name" placeholder="Name">
                  <div class="invalid-feedback" v-if="submitted && errors.has('name1')">{{ errors.first('name1') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input type="text" :class=" {'is-invalid': submitted && errors.has('plural1')}" class="form-control" id="plural1" name="plural1" data-vv-as="Plural" v-validate="'required'" v-model="currency.plural" placeholder="Plural">
                  <div class="invalid-feedback" v-if="submitted && errors.has('plural1')">{{ errors.first('plural1') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('symbol1')}" class="form-control" id="symbol1" name="symbol1" data-vv-as="Symbol" v-validate="'required'" v-model="currency.symbol" placeholder="Symbol">
                  <div class="invalid-feedback" v-if="submitted && errors.has('symbol1')">{{ errors.first('symbol1') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="native" class="col-sm-3 col-form-label offset-1 text-right">Symbol Native</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('native1')}" class="form-control" id="native1" name="native1" data-vv-as="Symbol Native" v-validate="'required'" v-model="currency.symbol_native" placeholder="Symbol Native">
                  <div class="invalid-feedback" v-if="submitted && errors.has('native1')">{{ errors.first('native1') }}</div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Currency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleUpdate">
            <div class="modal-body">
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" id="name" name="name" data-vv-as="Name" v-validate="'required'" v-model="currency.name" placeholder="Name">
                  <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('plural')}" class="form-control" id="plural" name="plural" data-vv-as="Plural" v-validate="'required'" v-model="currency.plural" placeholder="Plural">
                  <div class="invalid-feedback" v-if="submitted && errors.has('plural')">{{ errors.first('plural') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('symbol')}" class="form-control" id="symbol" name="symbol" data-vv-as="Symbol" v-validate="'required'" v-model="currency.symbol" placeholder="Symbol">
                  <div class="invalid-feedback" v-if="submitted && errors.has('symbol')">{{ errors.first('symbol') }}</div>
                </div>
              </div>
              <div class="form-group row">
                <label for="native" class="col-sm-3 col-form-label offset-1 text-right">Symbol Native</label>
                <div class="col-sm-7">
                  <input type="text" :class="{'is-invalid': submitted && errors.has('native')}" class="form-control" id="native" name="native" data-vv-as="Symbol Native" v-validate="'required'" v-model="currency.symbol_native" placeholder="Symbol Native">
                  <div class="invalid-feedback" v-if="submitted && errors.has('native')">{{ errors.first('native') }}</div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="delModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Currency</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are about to delete <b>{{currency.name}}</b>. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
            <button type="button" class="btn btn-primary" @click="deleteCurrency">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Currencies",
  data() {
    return {
      submitted: false,
      currencies: [],
      currency: {},
      api: `${this.$accounts.defaults.baseURL}/accounts/currencies`,
      columns: [
        { title: '#', data: 'index', defaultContent: 'N/A' },
        { title: 'Name', data: 'name', defaultContent: 'N/A' },
        { title: 'Plural', data: 'plural', defaultContent: 'N/A' },
        { title: 'Symbol', data: 'symbol', defaultContent: 'N/A' },
        { title: 'Native', data: 'symbol_native', defaultContent: 'N/A' },
        {
          title: 'Action',
          data: null,
          defaultContent: `
            <a href="javascript:void(0)"
               class="text-primary bg-white fa-border text-decoration-none edit"
              data-target="#editModal"
               data-toggle="modal" 
              title="Rename Registry">
               <i class="fa fa-edit"></i> Edit
            </a>
            <a href="javascript:void(0)"
               class="text-danger mg-l-5 bg-white fa-border text-decoration-none delete"
               data-target="#delModal"
               data-toggle="modal" 
               title="Delete Registry">
               <i class="fas fa-trash-alt"></i> Delete
            </a>`,
          className: 'text-center w-150',
          orderable: false
        }
      ],
    };
  },
  watch: {},
  mounted() {
    $('#addModal, #editModal, #delModal').on('hidden.bs.modal', (e) => {
      this.currency = {};
      this.submitted = false;
    });
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#currencies_table tbody').on('click', '.edit', function () {
          let currency = table.row($(this).parents('tr')).data();
          self.currency = currency;
          table.ajax.reload();
        });

        $('#currencies_table tbody').on('click', '.delete', function () {
          let currency = table.row($(this).parents('tr')).data();
          self.currency = currency;
        });
      });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addCurrency();
        }
      });
    },
    async addCurrency() {
      try {
        let currency = {
          name: this.currency.name,
          plural: this.currency.plural,
          symbol: this.currency.symbol,
          symbol_native: this.currency.symbol_native,
        };
        await this.$accounts.post(`accounts/currencies`, currency);
        this.$toastr.s('Currency added', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        console.error(e);
        this.$toastr.e('Currency add failed', 'Error');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    handleUpdate() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updateCurrency();
        }
      });
    },
    async updateCurrency() {
      try {
        let currency = {
          name: this.currency.name,
          plural: this.currency.plural,
          symbol: this.currency.symbol,
          symbol_native: this.currency.symbol_native,
        };
        await this.$accounts.patch(`accounts/currencies/${this.currency.currency}`, currency);
        this.$toastr.s('Currency updated', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        console.error(e);
        this.$toastr.e('Currency update failed', 'Error');
      } finally {
        $('#editModal').modal('hide');
      }
    },
    async deleteCurrency() {
      try {
        await this.$accounts.delete(`accounts/currencies/${this.currency.currency}`);
        this.$toastr.s('Currency delete', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        console.error(e);
        this.$toastr.e('Currency deleted failed', 'Error');
      } finally {
        $('#delModal').modal('hide');
      }
    }
  }
}
</script>

<style scoped>
</style>